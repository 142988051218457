import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SectionTitle from "../components/common/SectionTitle"
import styled from "styled-components"

const StyledPage = styled.div`
  max-width: 1400px;
  margin: 5rem auto;
`

const ShowSucsessPage = () => {
  return (
    <Layout>
      <StyledPage>
        <SectionTitle title="Thank You for your show submission" />

        <h2>Your show has been successfully submitted.</h2>
        <p>
          We will contact you once your show has been reviewed. Please allow
          upto 72 hours for feedback.
        </p>
        <Link to="/">Back to Home</Link>
        <br />
        <Link to="/show-submission">Back to Form</Link>
      </StyledPage>
    </Layout>
  )
}

export default ShowSucsessPage
